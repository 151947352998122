<template>
  <v-sheet>
    <v-container class="py-12">
      <v-expansion-panels
        multiple
        class="pt-3"
      >
        <!-- ===================================================================================================== -->
        <!-- Question 1                                                                                            -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">1. Who is Mallinckrodt, what is the case about, and why is there a Trust?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            Mallinckrodt <b>Pharmaceuticals</b> is an American-Irish domiciled manufacturer of specialty
            pharmaceuticals, generic drugs including generic Opioids and imaging agents. They are one of the
            largest manufacturers of hydrocodone and oxycodone.
            <br><br>
            The company has been implicated as a major contributor to the prescription opioid scandal surrounding the
            inappropriate prescription of oxycodone and hydrocodone in the United States.
            <br><br>
            In 2017 Mallinckrodt paid a $35 million fine to settle DEA complaints that it did not adequately
            address suspicious opioid orders, acknowledging “certain aspects of Mallinckrodt’s system to monitor
            and detect suspicious orders did not meet" DEA standards.
            <br><br>
            Using the DEA evidence and admissions by Mallinckrodt that its system was deficient, a number of
            lawsuits were filed against Mallinckrodt alleging it was negligent and was instrumental in the
            opioid crises in relation to the inappropriate prescribing of opioids.
            <br><br>
            In 2020 faced with the multiple opioid related lawsuits, the company filed for Chapter 11 Bankruptcy
            protection. The company's Court approved reorganization plan includes a $1.7 billion settlement to
            resolve thousands of lawsuits accusing it of deceptively marketing its opioids.
            <br><br>
            On March 2, 2022, the Bankruptcy Court entered an order approving the Joint Plan of Reorganization
            and the Disclosure Statement (<i>Content can be viewed at
            <router-link to="DocumentsMaster" target="_blank">{{ config.product.url }}</router-link>
            in the documents page</i>) which included procedures for establishing Trusts,
            including the IERP Trust, to distribute funds to eligible claimants for use
            in abatement of opioid misuse and abuse.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ===================================================================================================== -->
        <!-- Question 2                                                                                            -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">2. What is the {{ config.product.name }}?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            It is clear that a number of people and entities were damaged as a result of the actions of a
            number of companies, including Mallinckrodt, by their actions in facilitating the opioid crisis.
            Emergency Room Physicians are often involved in all aspects of care of patients suffering from
            Opioid Use Disorder (“OUD”). Covered by this Trust are the Emergency Room Physicians that are not
            employed by Hospitals (please see No. 4 below). These Independent Emergency Room Physicians (IERPs)
            were injured as well as other medical care treatment providers and facilities. The damages can be
            counted in lost time, billings etc. This damage was recognized by the US Trustee and it was
            ascertained that IERP’s, could have a substantial impact in helping alleviate the opioid misuse
            crises by utilizing effective abatement measures. Thus, the {{ config.product.name }} was created to provide
            distributions for IERP’s to use for abatement measures.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ===================================================================================================== -->
        <!-- Question 3                                                                                            -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">3. How can IERP’s help?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            ER physicians are often first contact that OUD patients have with the healthcare system. The emergency
            department is rightly referred to as "The Front Line". ER physicians have a unique perspective and
            understanding of the harm inflicted during the opioid crisis. The court has
            recognized the vital role IERP’s have in diagnosing and treating patients with OUD
            and agreed that the abatement measures authorized by the Trust can have a significant impact as
            part of addressing this crisis.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ===================================================================================================== -->
        <!-- Question 4                                                                                            -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">4. Who is an Independent Emergency Room Physician and what years are covered?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            Independent Emergency Room Physician is defined as an Emergency Room Physician that was
            a non-hospital employee that worked in an emergency room and subject to EMTALA during the
            years 2001-2019.<br>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ===================================================================================================== -->
        <!-- Question 5                                                                                         -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">5. Who do I contact for questions?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            For questions related to the claims process please email
            <a
              :href="`mailto:${config.product.email_claims}`"
              target="_blank"
            >{{ config.product.email_claims }}</a>
            <br>
            For questions related to the trust email
            <a
              :href="`mailto:${config.product.email_trustee}?subject=${email_subject_questions}&body=${email_body_questions}`"
              target="_blank"
            >{{ config.product.email_trustee }}</a>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </v-sheet>
</template>

<script>
import config from '@/configs'

export default {
  data: () => ({
    config,
    email_subject_questions: 'IERP Questions or Missed Deadline',
    email_body_questions: 'Please consider the questions or missed deadline and reason explained below...'
  })
}
</script>
