<template>
  <v-sheet color="transparent">
    <ToolbarMaster />
    <Documents
      :docs="doc"
    />
    <FooterMaster />
  </v-sheet>
</template>

<script>
import ToolbarMaster from '@/components/ui/ierp/toolbar/ToolbarMaster'
import Documents from '@/components/ui/ierp/pages/Documents.vue'
import FooterMaster from '@/components/ui/ierp/pages/FooterMaster.vue'

export default {
  components: {
    ToolbarMaster,
    Documents,
    FooterMaster
  },
  data() {
    return {
      doc: [{
        link: '1: IERP Trust',
        href: 'https://ierp-documents.s3.amazonaws.com/IERP+Trust+Agreement.pdf'
      },{
        link: '2: IERP Trust Authorized Abatement Purposes',
        href: 'https://ierp-documents.s3.amazonaws.com/Authorized+Abatement+Purposes.pdf'
      },{
        link: '3: IERP Trust Distribution Procedures',
        href: 'https://ierp-documents.s3.amazonaws.com/IERP+Trust+Distribution+Procedures.pdf'
      },{
        link: '4: Mallinckrodt Bankruptcy Case Docket',
        href: 'https://restructuring.ra.kroll.com/mallinckrodt/Home-DocketInfo'
      }]
    }
  }
}
</script>
