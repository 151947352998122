<template>
  <v-sheet id="home">
    <v-container class="py-12">
      <v-row id="banner_row">
        <div class="doctors_banner">
        </div>
      </v-row>
      <v-row id="content_row">
        <v-col cols="12" md="12" lg="12">
          <h1 class="text-h6 text-sm-h5 secondary--text mt-4">
            Welcome to the Independent Emergency Room Physician Trust, referred to as IERP Trust or Trust I. In March
            2022, with monies from the bankruptcy of Mallinckrodt plc (“Mallinckrodt”) a manufacturer and supplier of
            opioids, a number of Trusts were established to distribute funds to eligible claimants for use in abatement
            of opioid misuse and abuse. One of the Trusts created by the Bankruptcy Court, is the IERP Trust. This
            trust was established to fund abatement strategies that benefited Independent Emergency Room Physicians
            (IER Physician or IERP). An Independent Emergency Room Physician is defined as an Emergency Room Physician
            that was a non-hospital employee that worked in an Emergency Room subject to EMTALA during the years
            2001-2019. After the Trust was created a Claims process for IERPs was established. That Claims process
            concluded on July 31, 2023.
            <br/><br/>
            The IERP Trust funds have been used and continue to be used for opioid abatement. A number of different
            programs with various focuses have either been created with or have been provided with Trust distributions
            enabling those programs to provide abatement for OUD. It is critical to understand that a patient in the ER
            that has experienced a non fatal overdose has the highest rate of overdose death in the following year. We
            believe that a number of the programs funded by the Trust can identify and assist in treatment of these
            patients.
            <br/><br/>
            To date, the Trust has funded peer support services that enable an Emergency Room Doctor to immediately
            contact peer support for OUD diagnosed patients. These programs have been created in the ERs in Montana,
            Massachusetts, Colorado, Utah, Oklahoma, North Carolina, South Carolina, and Nevada.
            <br/><br/>
            By being able to refer patients to peer support quickly, ER Physicians know when they discharge a patient
            they will have: 1) continued support, 2) help finding resources, 3) encouragement from a peer to start or
            continue various treatment, 4) support with receiving and continuing Medication Assisted Treatment (MAT),
            and 5) someone to assist with resolving issues such as obtaining emotional support, insurance,
            transportation, housing, food, and employment. The goal is to give the ER Physician a resource they can rely
            on and which has been proven to be effective to reduce the risk of patients returning to the ER with OUD
            related problems including subsequent overdose.
            <br/><br/>
            The Trust has also provided Mobilize Recovery the ability to purchase a device called
            <a aria-describedby="footnote-label" href="#bridgefootnote" id="bridgefootnotes-ref">“The Bridge”</a>. The
            Bridge device is a small, electrical nerve stimulator that's worn behind the patient’s ear intended to help
            reduce opioid withdrawal symptoms. The goal is that with reduced withdrawal symptoms, patient engagement in
            opioid addiction abatement treatments will increase. Mobilize Recovery will distribute to these to entities
            that are providing peer support to EDs.
            <br/><br/>
            Trust funds have also enabled Emergency Room Physicians to provide education to other Emergency Room doctors
            on why it is important to offer and initiate Medical Assisted Treatment (MAT) in the ED, including education
            on reducing stigma associated with opioid use disorder. These programs have begun in Missouri and in Las
            Vegas.
            <br/><br/>
            Another program requested by a Claimant and funded by the Trust, is the installation of vending machines in
            Missoula MT that supply Naloxone and fentanyl test strips for free. In order to reach as many individuals as
            possible and encourage people to obtain assistance, the wrap on the machine has an 800 # that refers a person
            directly to peer support.
            <br/><br/>
            Any questions about the Trust I can be directed via email to:<br>
            info@ierptrust.com <br>
            Claims Administrator,<br>
            IERP Trust, 1121 Park West Blvd.<br>
            Suite B #186<br>
            Mt. Pleasant, SC 29466-7122
          </h1>
          <br/><br/>
           <ol>
            <li id="bridgefootnote"><i>The bridge is a FDA approved device.</i></li>
          </ol>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import config from '@/configs'

export default {
  data: () => ({
    config
  })
}
</script>

<style>
.doctors_banner {
  background-image: url('../../../../assets/images/doctors_banner.jpg');
  width: 1200px;
  height: 400px;
  background-position: center;
  background-size: cover;
  text-align: center;
}
</style>
